import { DefaultTheme } from 'styled-components';
import { themes } from '@geberit/gdds';

export const THEMES: ThemesProps = {
  DEFAULT: themes.defaultTheme,
  GDDS: themes.comTheme,
  KOLO: themes.koloTheme,
  NORD: themes.nordicsTheme,
  TWYFORD: themes.twyfordTheme,
  XY: themes.xyTheme,
};

export const THEME_NAMES = {
  DEFAULT: 'DEFAULT',
  GDDS: 'GDDS',
  NORD: 'NORD',
  KOLO: 'KOLO',
  TWYFORD: 'TWYFORD',
  XY: 'XY',
} as const;

export type ThemesProps = {
  [key in ThemeNameProps]: DefaultTheme;
};

export type ThemeNameProps = (typeof THEME_NAMES)[keyof typeof THEME_NAMES];
export type ThemeNamePropsWithoutDefault = Exclude<ThemeNameProps, 'DEFAULT'>;
